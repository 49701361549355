header {
  background-color: #275fa3;
  color: #fff;
  padding: 1px;
  text-align: center;
  background-image: linear-gradient(
      to bottom,
      rgb(255 255 0 / 50%),
      rgb(0 0 255 / 50%)
    ),
    url("../img/c2.jpg");
}
.EventPagemain {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 20px;
  background-color: #92a3c5;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.card {
  width: 400px;
  /* background-color: #638bdd; */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 10px 20px;
  transition: transform 0.3s ease;
  overflow: hidden;
}

.card:hover {
  transform: translateY(-5px);
}

.card-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.card-content {
  padding: 20px;
}

.card-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.card-description {
  font-size: 16px;
  color: #555;
}

.image {
  width: 100%;
  height: auto;
  display: block;
}

.overlay {
  position: absolute;
  top: 70%;
  left: 49%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black background */
  padding: 20px;
  color: #fff;
  text-align: center;
}
.overlay h1 {
  margin: 0;
  font-size: 2rem;
}

.overlay p {
  margin: 10px 0;
  font-size: 1.2rem;
}
.extra-content {
  text-align: center;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.image-container img {
  margin: 5px;
  width: 405px;
  height: auto;
}

.styled-button {
  background-color: #dbb727; /* Green */
  border: none;
  color: rgb(15, 14, 14);
  padding: 9px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 18px;
}
.styled-button:hover {
  background-color: #5e33ad;
}

.styled-button:active {
  background-color: #2f1682;
}
