.footer {
    height: auto;
    padding: 30px;
    display: flex;
    justify-content: space-evenly;
    align-items: start;
    background-color: #708090ca;
    color: white;
}

.second {
    display: flex;
    gap: 10px;
}

.main-logo1 {
    height: 100px;
    width: 100px;
    background-image: url("../img/logo1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    mix-blend-mode: multiply;
}

.main-logo2 {
    height: 200px;
    width: 170px;
    background-image: url("../img/logo2.png");
    background-size: contain;
    background-repeat: no-repeat;

}

.feature {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.feature h1 {
    color: white;
    font-size: 2rem;
}

.content {
    display: flex;
    flex-direction: column;
    color: rgb(255, 255, 255);
    gap: 3px;
}

.content a {
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-weight: 400;
    font-size: 1.2rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.content a:hover {
    color: blue;
}


.first {
    width: 400px;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: start;
}

.first h1 {
    font-size: 2rem;
    color: white;
}


.socials {
    height: 70px;
    width: 200px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.firstlogo a {
    height: 100%;
    width: 100%;
}

.firstlogo {
    filter: invert(100%);
    height: 60%;
    width: 20%;
    background-image: url("../img/1test.jpg");
    background-size: cover;
    background-repeat: no-repeat;

}

.secondlogo {
    height: 60%;
    width: 20%;
    background-image: url("../img/2test.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    mix-blend-mode: multiply;
}

.thirdlogo {
    height: 60%;
    width: 20%;
    background-image: url("../img/3test.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    mix-blend-mode: multiply;
}

.copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #434d57;
    color: white;
    padding: 15px;
    gap: 10px;
}

.address .c2 p {

    font-size: 1.2rem;
}

.c1 {

    font-size: 1.3rem;
    font-weight: 500;
}




@media screen and (min-width: 280px) and (max-width: 800px) {


    .footer {
        flex-direction: column;
        height: auto;
    }

    .first {
        width: 90vw;
    }

    .address {
        width: 90vw;
    }

    .c1 {

        font-size: 1rem;
        font-weight: 500;
    }
    .main-logo1{
        width: 90px;
        height: 90px;
    }
    .main-logo2{
        width: 90px;
        height: 90px;
    }

}

/* .first {
    width: 60%;
}
.heading {
    height: 50px;
    font-size: xxx-large;
    font-weight: bolder;
    font-family: Arial, Helvetica, sans-serif;
}
.socials {
    height: 100px;
    width: 300px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.firstlogo a {
    height: 100%;
    width: 100%;
}
.firstlogo {
    height: 60%;
    width: 20%;
    background-image: url("../img/1test.jpg");
    background-size: cover;
    background-repeat: no-repeat;

}
.secondlogo {
    height: 60%;
    width: 20%;
    background-image: url("../img/2test.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}
.thirdlogo {
    height: 60%;
    width: 20%;
    background-image: url("../img/3test.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}
.email {
    margin: 2%;
    height: 40px;
    width: 70%;
    border: 1.5px solid black;
    display: flex;
    align-items: center;
}
.address {
    margin: 2%;
    height: 70px;
    width: 70%;
    border: 1.5px solid black;
    display: flex;
    align-items: center;
}
.c1 {
    height: 100%;
    width: 25%;
    display: flex;
    font-size: x-large;
    align-items: center;
    position: relative;
    left: 5%;
}
.c2 {
    height: 100%;
    width: 100%;
    display: flex;
    font-size: x-large;
    align-items: center;
    position: relative;
    left: 5%;
}
.copyright {
    height: 100px;
    width: 100%;
    display: inline;
}
.l1 {
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: x-large;
}
.l2 {
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: large;
}
.second {
    height: 100%;
    width: 30%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.footer {
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 100px;
}
.first {
    height: 100%;
    width: 60%;
    margin-top: 15%;
}
.heading {
    height: 50px;
    font-size: xxx-large;
    font-weight: bolder;
    font-family: Arial, Helvetica, sans-serif;
}
.socials {
    height: 100px;
    width: 300px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.firstlogo a {
    height: 100%;
    width: 100%;
}
.firstlogo {
    height: 60%;
    width: 20%;
    background-image: url("../img/1test.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}
.secondlogo {
    height: 60%;
    width: 20%;
    background-image: url("../img/2test.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}
.thirdlogo {
    height: 60%;
    width: 20%;
    background-image: url("../img/3test.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}
.email {
    margin: 2%;
    height: 40px;
    width: 80%;
    border: 1.5px solid black;
    display: flex;
    align-items: center;
}
.address {
    margin: 2%;
    height: 70px;
    width: 80%;
    border: 1.5px solid black;
    display: flex;
    align-items: center;
}
.c1 {
    height: 100%;
    width: 25%;
    display: flex;
    font-size: x-large;
    align-items: center;
    position: relative;
    left: 5%;
}
.c2 {
    height: 100%;
    width: 100%;
    display: flex;
    font-size: x-large;
    align-items: center;
    position: relative;
    left: 5%;
}
.copyright {
    height: 100px;
    width: 100%;
    display: inline;
}
.l1 {
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: x-large;
}
.l2 {
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: large;
}
.second {
    height: 100%;
    width: 30%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.main-logo1 {
    height: 100px;
    width: 100px;
    background-image: url("../img/logo1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}
.main-logo2 {
    height: 200px;
    width: 170px;
    background-image: url("../img/logo2.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    top: 13%;
}
.feature {
    padding: 100px;
    padding-left: 200px;
    padding-right: 200px;
    font-size: larger;
}
.content a {
    color: black;
    margin-top: 10px;
    text-decoration: none;
} */