@import url('https://fonts.googleapis.com/css2?family=Protest+Revolution&family=Rock+Salt&display=swap');

.proMritkala {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    padding: 20px;
    background-color: #fff;
}

.mritkalaLogo {
    width: 300px;
    height: 300px;
}

.mritkalaLogo img {
    width: 100%;
}

.aboutMritkala {
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.aboutMritkala h1 {
    font-size: 3rem;
    color: #0B4619;
    text-align: center;
}

.aboutMritkala p {
    padding: 0 40px;
    font-size: 1.2rem;
    text-align: center;
}

.imgAbout {
    height: 200px;
    width: 400px;
}

.imgAbout img {
    width: 100%;
}


.imgSectionMritkala {
    background-image: linear-gradient(#0b46193b, #0b461942), url(../img/TulsiPot1.413c2e69.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imgSectionMritkala h1 {
    font-family: "Protest Revolution", sans-serif;
    text-shadow: 8px 1px 10px rgb(0, 0, 0);
    font-size: 9rem;
    color: #FFCC1D;
}

.productSectionMritkala {
    padding: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;



}

.productSectionMritkala h1 {
    color: #0B4619;
}

.productSectionMritkala p {
    color: #0B4619;
    padding: 30px;
    font-size: 1.2rem;
    text-align: center;
}

.ProductImgMritkala {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    padding: 30px;
    width: 780px;
    height: 350px;
    background-color: #F3D6C5;
}

.ProductImgMritkala img {
    width: 400px;
    height: 280px;
    transition: transform 0.9s ease;
    /* Smooth transition for the transform property */
}

.ProductImgMritkala img:hover {
    transform: scale(1.4);
    width: 400px;
    /* Apply the scale transformation on hover */
}

.linkMritkala {
    cursor: pointer;
    font-weight: 500;
}

.linkMritkala:hover {
    color: red;
    cursor: pointer;
    font-weight: 500;
}

.communityMritkala {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

.communitySection {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 1000px;
}

.communityContent {
    width: 400px;
}

.communityContent p {
    font-size: 1.2rem;
    padding: 10px;
}

.commnityImg {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.commnityImg img {
    width: 100%;
}

.OUR-INVOLVEMENT {
    margin-top: 50px;
    display: flex;
    padding: 30px;
    flex-direction: column;
}

.OUR-INVOLVEMENT p {
    padding: 40px;
}

.ProjectHeadMritkala {
    display: flex;
    align-items: start;
    justify-content: space-evenly;
}

.ProhjectHeadImg {
    border-radius: 1rem;
    width: 400px;
    height: 500px;
    position: relative;
}

.ProhjectHeadImg img{
    border-radius: 2rem;
}

.positionHead {
    bottom: 12%;
    right: -10%;
    border: 5px solid #ececec;
    background-color: #FFCC1D;
    border-radius: 2rem;
    position: absolute;
}
.positionHead  h1{
    font-size: 2rem;
}

.aboutHead {
    display: flex;
    flex-direction: column;
    width: 400px;
}


.floatingM {  
    animation-name: floatingM;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transform-style: preserve-3d; /* Preserve 3D transformations */
}

@keyframes floatingM {
    0% { 
        transform: translate(0, 0px) rotateY(0deg); /* Initial position */
    }
    100% { 
        transform: translate(0, 0px) rotateY(360deg); /* Full rotation */
    }    
}




.aboutHead p {}


@media screen and (min-width: 280px) and (max-width: 800px) {

    .mritkalaLogo{
        width: 90vw;
    }
    .communityMritkala h1{
        font-size: 2rem;
    }

    .aboutHead h1{
        font-size: 2rem;
    }

    .imgAbout{
        width: 90vw;
        height: auto;
    }
    .productSectionMritkala {
        padding: 10px;
    }
    .imgSectionMritkala{
        width: 100vw;
        align-items: center;
    }

    .imgSectionMritkala h1{
        font-size: 4rem;
    }

    .ProductImgMritkala{
        width: 90vw;
        height:auto ;
    }
    .ProductImgMritkala img {
        width: 90vw;
        height: auto;
    }
    .communitySection{
        flex-direction: column;
        width: 90vw;
        text-align: center;
    }
    .ProjectHeadMritkala{
        align-items: center;
        flex-direction: column;
    }
    .OUR-INVOLVEMENT{
        text-align: center;
    }
    .OUR-INVOLVEMENT h1{
        font-size: 2rem;

    }
    .OUR-INVOLVEMENT p{
        padding: 10px;
    }

    .commnityImg{
        width: 90vw;
        height: auto;
    }
    .ProhjectHeadImg {
        width: 90vw;
        height: auto;
    }

    .communityContent{
        width: 90vw;
    }

    .positionHead {
        display: none;
        bottom: 10%;
        right: 0%;
        border: 5px solid #ececec;
        background-color: #FFCC1D;
        border-radius: 2rem;
        position: absolute;
    }
    .aboutHead{
        width: 90vw;
    }
}

