#app {
  height: 100%;
}
html,
* {
  font-family: "DM Sans", sans-serif;
}

body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}
.heading-land {
  font-size: 2.4rem;
  text-align: center;
  margin-top: 5%;
  color: #fff;
  font-weight: 600;
}

#landingpageSlider {
  height: 400px;
  width: 400px;
}

#mySwiper {
  height: 550px;
  width: 900px;
  /* padding-top: 50px; */
  /* padding-bottom: 50px; */
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  /* width: 300px; */
  width: 180px;
  height: 250px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

@media screen and (max-width: 786px) {
  .heading-land {
    margin-left: 25%;
    margin-bottom: 5%;
  }
}
@media screen and (max-width: 375px) {
  .heading-land {
    /* margin-left: 25%; */
    /* font-size: 3rem; */

    /* margin-bottom: 5%; */
    margin-left: -10px;
  }
}
