.Gutargun {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 70px;
}

.Gutargun h1 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.Gutargun p {
    font-size: 1.2rem;
}

.magazinSection {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 70px;
}

.magazinSection h1 {
    font-size: 2rem;
    font-weight: 600;
}

.discriptionMagazin h4{
    color: brown;
    border: 1px solid brown;
    padding: 5px;
    cursor: pointer;
}

.discriptionMagazin h4:hover{
    color: blueviolet;
    border: 1px solid blueviolet;
}
.boxMagazin {
    background-color: aliceblue;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    display: flex;
    justify-content: space-between;
    width: 1100px;
    height: auto;
    align-items: start;
    overflow: hidden;
    border-radius: 1rem;
}

.imgBoxMagazin {
    width: 25vw;
    height: auto;

}

.discriptionMagazin {
    padding: 30px;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-around;
    width: 60vw;
    height: auto;
    color: black;
}

.discriptionMagazin p {
    padding: 10px;
}

/* 
.headGutargun{
    width: 400px;
}

.headGutargun img{
    width: 100%;
} */

@media screen and (min-width: 280px) and (max-width: 800px) {
    .boxMagazin {
        width: 90vw;
        height: auto;
        flex-direction: column;
    }

    .imgBoxMagazin{
        width: 90vw;
    }

    .discriptionMagazin {
        width: 90vw;
    }
}