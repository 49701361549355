.navbar {
    height: 100px;
    background-color: rgba(0, 0, 0, 0.852);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav-logoborder {
    height: 90px;
    width: 16%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    padding-left: 2%;
    /* right: 7%; */
}
.logo1 {
    height: 100%;
    width: 50%;
}
.logo2 {
    height: 100%;
    width: 50%;
}
.logo1 {
    background-image: url("../img/img2enactus.png");
    background-size: contain;
    background-repeat: no-repeat;
}
.logo2 {
    background-image: url("../img/img1enactus.png");
    background-size: contain;
    background-repeat: no-repeat;
}
.NavContent {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    /* left: 10%; */
}
.NavContent a {
    text-decoration: none;
    color: white;
    font-weight: 300;
    font-size: large;
}
.NavContent a:hover {
    color: grey;
}