.slider-container {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.slider {
  display: flex;
  transition: transform 0.5s ease;
}

.slide {
  flex: 0 0 100%; /* Each slide takes full width */
}

img {
  width: 100%;
  height: auto;
}

.center {
  transform: translateX(0%);
}

.left {
  transform: translateX(-100%);
}

.right {
  transform: translateX(100%);
}

button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

button:first-child {
  left: 10px;
}

button:last-child {
  right: 10px;
}


.slider-button{
  background: transparent;
  font-size: 20px;
}
