.ProjeactAstitav {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 10px;
    flex-direction: column;
    background-color: #fff;
}

.astitavLogo {
    width: 200px;
    height: 200px;
}

.astitavLogo img {
    width: 100%;
}

.headingAstitav {}

.headingAstitav h1 {
    font-size: 2rem;
    color: #f3ba00;

}

#headAstitav {
    font-size: 5rem;
    font-weight: 300;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #0B4619;
}

.paraAstitav {
    padding: 30px;
    font-size: 1.2rem;
    text-align: center;
}

.AstitavCards {
    display: flex;
    justify-content: space-around;
    width: 1000px;
}

.cardAsti {

    background-color: #f3ba00a6;
    color: white;
    height: 400px;
    width: 280px;
    border-radius: 2rem;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.cardAsti:hover {
    background-color: #f3ba00;
}

.cardAsti h1 {
    font-size: 2rem;
    margin: 0;
    color: #0B4619;
}

.cardAsti p {
    font-size: 1.1rem;
    padding: 10px;
    color: rgb(0, 0, 0);
    font-weight: 400;
}

.cardAsti .cardLink {
    text-decoration: none;
    margin: 0;
    padding: 10px;

    color: white;
    font-size: 1.2rem;
    cursor: pointer;
}

.cardAsti .cardLink:hover {
    color: rgb(58, 97, 0);
    font-size: 1.2rem;
    cursor: pointer;
}

.curve {
    background-image: url(../img/sv.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
}

.teamAstitav {
    height: 100%;
    padding: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #0B4619;
    color: white;
}


.teamMemberAstitav {
    color: white;
    margin-top: -40px;
    text-align: center;
}

.teamMemberAstitav h1 {
    color: white;
    text-align: center;
}

.teamMemberAstitav h4 {
    font-weight: 400;
    font-size: 1.2rem;
    color: white;
    text-align: center;
}

.teamDiscription {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    margin-top: 40px;
}

.imgSectionOFTeam {
    height: 400px;
    width: 350px;
    margin-right: -200px;
    transition: margin-right 0.5s ease;
    /* Add transition for smooth movement */
}



.imgSectionOFTeam img {
    width: 100%;
    border-radius: 1rem;
}

.dis {
    background-color: #f3ba00;
    width: 500px;
    padding: 5px;
    color: white;
    color: #0B4619;
    margin-left: -240px;
    text-align: center;
    transition: margin-left 0.5s ease;
    /* Add transition for smooth movement */
}




.dis h1 {
    color: white;
}


.dis p {
    font-size: 1.2rem;
}


.teamDiscription:hover .dis {
    margin-left: 0px;
}

.teamDiscription:hover .imgSectionOFTeam {
    margin-right: 0px;
}

.endcurve {
    background-image: url(../img/end.svg);
    height: 240px;
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 10px;
}


@media screen and (min-width: 280px) and (max-width: 800px) {

    #headAstitav {
        text-align: center;
    }

    .AstitavCards {
        flex-direction: column;
        width: 90vw;
        height: auto;
        align-items: center;
        gap: 30px;
    }

    .teamAstitav{
        height: auto;
        gap: 20px;
        display: flex;
    }

    .teamDiscription{
        align-items: center;
        flex-direction: column;
        height: auto;
        margin: 0;
    }

    .imgSectionOFTeam img {
        border-radius: 0rem;
    }

    .imgSectionOFTeam{
        border-radius: 0;
        margin: 0;
        width: 90vw;
        height: auto;
    }
    .dis{
        margin: 0;
        width: 90vw;
        height: auto;
    }
}