.OngoingProject {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 20px;
}

.OngoingProject h1 {
  font-size: 2rem;
}

.ProjectCon {
  display: flex;
  width: 100%;
}

.ProjectBox {
  background-color: darkkhaki;
  border-radius: 1rem;
  height: 400px;
  width: 300px;
  overflow: hidden;
  cursor: pointer;
}

.ProjectBox img {
  width: 100%;
}

.ProjectBox:hover {
  background-color: black;
  z-index: 100;
}

.swiperBOx {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.swiperDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

#swiperBOxOngoing {
  width: 80%;
  padding: 10px !important;
}

#swiper-slide-ongoing {
  text-align: center;
  font-size: 18px;
  background: #fff;
  flex-shrink: unset !important;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* import './DirectionAwareHoverEffect.css'; */
.container {
  position: relative;
  /* width: 1200px; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  transform-style: preserve-3d;
  perspective: 500px;
  gap: 10px;
  margin: auto;
  border-radius: 1rem;
}

.container .box {
  position: relative;
  width: 280px;
  height: 380px;
  border-radius: 1rem;
  background: #000;
  transition: 0.5s;
  transform-style: preserve-3d;
  overflow: hidden;
  margin-right: 15px;
  margin-top: 45px;
}

.container:hover .box {
  /* transform: rotateY(25deg); */
}

.container .box:hover ~ .box {
  /* transform: rotateY(-25deg); */
}

.container .box:hover {
  transform: rotateY(0deg) scale(1.1);
  z-index: 1;
  box-shadow: 0 25px 40px rgba(88, 88, 88, 0.5);
}

.container .box .imgBx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.container .box .imgBx:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, aqua, #000);
  z-index: 1;
  opacity: 0;
  transition: 0.5s;
  mix-blend-mode: multiply;
}

.container .box:hover .imgBx:before {
  opacity: 1;
}

.container .box .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container .box .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 20px;
  align-items: flex-end;
  box-sizing: border-box;
  opacity: 0;
  /* Hide content by default */
  transition: opacity 0.5s;
}

.container .box:hover .content {
  opacity: 1;
  /* Show content on hover */
}

.container .box .content h2 {
  color: #fff;
  transition: 0.5s;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 20px;
  transform: translateY(200px);
  transition-delay: 0.3s;
}

.container .box:hover .content h2 {
  transform: translateY(0px);
}

.container .box .content p {
  color: #fff;
  transition: 0.5s;
  font-size: 14px;
  transform: translateY(200px);
  transition-delay: 0.4s;
}

.container .box:hover .content p {
  transform: translateY(0px);
}

.past {
}

.pastProject {
  width: 240px;
  height: 320px;
}

.pastProject img {
  width: 100%;
}

.swiper2 {
  padding: 20px;
  width: 280px;
  height: 350px;
}

.swiper-slide2 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

/* showCaseSection */
.showCaseSection {
  /* background-color: rgb(223, 223, 223); */
  width: 100%;
  height: 100%;
}

.showCase {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  height: 400px;
}

.imgSection {
  height: 380px;
  width: 350px;
  overflow: hidden;
  border-radius: 2rem 0rem 0rem 2rem;
}

.imgSection img {
  width: 100%;
}

.discription {
  background-color: #0b4619;
  color: white;
  padding: 20px;
  height: 380px;
  width: 600px;
  border-radius: 0rem 2rem 2rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.discription h1 {
  font-size: 30px;
  color: #ffcc1d;
}

.discription p {
  height: 200px;
  font-size: 1rem;
  font-weight: 400;
}

/* CSS */
.button-17 {
  align-items: center;
  appearance: none;
  background-color: #ffcc1d;
  border-radius: 15px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 200px;
  will-change: transform, opacity;
  z-index: 0;
}

.button-17:hover {
  background: #f6f9fe;
  color: #174ea6;
}

.button-17:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%),
    0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.button-17:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.button-17:not(:disabled) {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.button-17:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0 2px 3px 0,
    rgba(60, 64, 67, 0.15) 0 6px 10px 4px;
}

.button-17:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.button-17:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, 0.3) 0 4px 4px 0,
    rgba(60, 64, 67, 0.15) 0 8px 12px 6px;
}

.button-17:disabled {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}
