* {
  font-family: "DM Sans", sans-serif;
}

.landingpage {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* background-color: aquamarine; */
  background: rgb(220, 247, 113);
  background: rgba(0, 255, 255, 0.084);
  position: relative;
  right: 0;
}

.contentLandingPage {
  position: absolute;
  top: 20%;
  left: 5%;
  width: 700px;
}

.contentLandingPage h1 {
  font-size: 5rem;
  color: rgb(0, 122, 82);
}

.landingPageImg {
  position: absolute;
  top: 4;
  right: 6%;
  width: 600px;
}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px) rotate(0deg);
  }

  50% {
    transform: translate(0, 15px) rotate(10deg);
  }

  100% {
    transform: translate(0, -0px) rotate(0deg);
  }
}

.HomeWh0WeAre {
  background-color: rgb(213, 200, 255);
}

.HomeCarousel {
  background-color: rgba(141, 168, 255, 0.212);
}

.CuerySEC {
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.Queary {
  color: transparent;
  -webkit-text-stroke: 0.7px #000000;
  width: 50%;
  cursor: pointer;
}

.Queary:hover {
  color: rgb(0, 0, 0);
}

.Queary h1 {
  text-align: center;
  font-size: 6rem;
  text-transform: uppercase;
}

.subjectSecProL {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border-radius: 1rem;
  padding: 20px;
  height: 600px;
  width: 560px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  color: rgb(0, 0, 0);
}

/* Styling for form container */
.formContainer {
  max-width: 100%;
  margin: 0 auto;
}

/* Styling for form elements */
.formContact {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

input[type="text"],
input[type="email"],
textarea {
  color: black;
  width: 350px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  resize: vertical;
}

.contactbtn {
  text-align: center;
  display: block;
  width: 350px;
  padding: 0.75rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contactbtn:hover {
  background-color: #002348;
}

@media screen and (max-width: 1024px) {
  .landingpageimg {
    width: 500px;
    height: 500px;
    position: absolute;
    top: -150px;
    /* left: 80px; */
  }

  .contentLandingPage {
    position: absolute;
    top: 8%;
    left: 5%;
    width: 90%;
    text-align: center;
  }
  .contentLandingPage h1 {
    margin-left: 8%;
    font-size: 4em;
  }
}

@media screen and (max-width: 820px) {
  .landingpageimg {
    width: 350px;
    height: 350px;
    position: absolute;
    top: -60px;
    left: 150px;
  }

  .contentLandingPage {
    position: absolute;
    top: 8%;
    left: 5%;
    width: 90%;
    text-align: center;
  }
  .contentLandingPage h1 {
    margin-left: 8%;
    font-size: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .landingpageimg {
    width: 400px;
    height: 400px;
    position: absolute;
    top: -100px;
  }

  .contentLandingPage {
    position: absolute;
    top: 8%;
    /* left: 5%; */
    /* width: 700px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 90%; */
    text-align: center;
    margin: 0;
  }
  .contentLandingPage h1 {
    /* margin-left: 5%; */
    /* margin: 0; */
    /* width: 100%; */
    text-align: center;
  }
}
@media screen and (max-width: 375px) {
  .landingpageimg {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 50px;
    left: 350px;
  }

  .contentLandingPage {
    position: absolute;
    top: 8%;
    left: 5%;
    /* width: 700px; */
    width: 90%;
    text-align: center;
  }
  .contentLandingPage h1 {
    margin-left: 5%;
  }
}
